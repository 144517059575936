import '../styles/globals.css'
import 'dayjs/locale/zh-cn'

import { getCookie, setCookie } from 'cookies-next'
import { merge } from 'lodash'
import type { NextPage } from 'next'
import React from 'react'

import {
    getCsrfToken,
    getProviders,
    SessionProvider,
} from '@stringke/next-auth/react'
import NextApp, { type AppContext, type AppProps } from 'next/app'
import Head from 'next/head'

import {
    ColorSchemeProvider,
    MantineProvider,
    type ColorScheme,
} from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { useMediaQuery } from '@mantine/hooks'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'

import { RouterTransition } from '../components/RouterTransition'
import { api } from '../utils/api'

export interface BaseProps {
    colorScheme: ColorScheme
    auth: {
        providers: NonNullable<Awaited<ReturnType<typeof getProviders>>>
        csrfToken: NonNullable<Awaited<ReturnType<typeof getCsrfToken>>>
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
declare type GetElement = React.ComponentType<
    React.PropsWithChildren<BaseProps>
>

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P & BaseProps, IP> & {
    getLayout?: GetElement
}

export type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
    colorScheme: ColorScheme
}

function DefaultLayout({ children }: { children: React.ReactNode }) {
    return <>{children}</>
}

const App = (props: AppPropsWithLayout) => {
    const {
        Component,
        pageProps: { session, ...rawPageProps },
    } = props

    const pageProps = rawPageProps as BaseProps

    const [colorScheme, setColorScheme] = React.useState<ColorScheme>(
        pageProps.colorScheme,
    )

    const toggleColorScheme = React.useCallback(
        (value?: ColorScheme) => {
            const nextColorScheme =
                value || (colorScheme === 'dark' ? 'light' : 'dark')

            setColorScheme(nextColorScheme)

            setCookie('mantine-color-scheme', nextColorScheme, {
                maxAge: 60 * 60 * 24 * 30,
            })
        },
        [colorScheme],
    )

    const isDark = useMediaQuery('(prefers-color-scheme: dark)', false, {
        getInitialValueInEffect: false,
    })

    React.useEffect(() => {
        if (isDark) {
            toggleColorScheme('dark')
        } else {
            toggleColorScheme('light')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDark])

    const Layout = Component.getLayout ?? DefaultLayout

    return (
        <SessionProvider session={session}>
            <ColorSchemeProvider
                colorScheme={colorScheme}
                toggleColorScheme={toggleColorScheme}
            >
                <MantineProvider
                    theme={{ colorScheme }}
                    withGlobalStyles
                    withNormalizeCSS
                    withCSSVariables
                >
                    <Notifications position={'top-center'} />
                    <ModalsProvider>
                        <Head>
                            <title>订餐</title>
                            <meta
                                name='viewport'
                                content='width=device-width, initial-scale=1.0, min-width=400'
                            />
                        </Head>
                        <RouterTransition />
                        <DatesProvider
                            settings={{
                                locale: 'zh-cn',
                                firstDayOfWeek: 0,
                            }}
                        >
                            <Layout {...pageProps}>
                                <Component {...pageProps} />
                            </Layout>
                        </DatesProvider>
                    </ModalsProvider>
                </MantineProvider>
            </ColorSchemeProvider>
        </SessionProvider>
    )
}

App.getInitialProps = async (appContext: AppContext) => {
    const appProps = await NextApp.getInitialProps(appContext)

    const providers = await getProviders()
    const csrfToken = await getCsrfToken()

    const colorScheme =
        getCookie('mantine-color-scheme', appContext.ctx) || 'light'

    return {
        ...appProps,
        pageProps: merge(appProps.pageProps, {
            colorScheme,
            auth: {
                providers,
                csrfToken,
            },
        }) as BaseProps,
    }
}

export default api.withTRPC(App)
